.content {
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .content:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
  }
  .ant-card {
    border: none;
  }

  .about-para {
    margin-top: 10px;
    background: #504d4d;
    padding: 10px;
    border-radius: 10px;
  }
  
  .ant-card-title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    text-align: center;
  }
  
  .ant-typography-paragraph {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  .ant-typography-paragraph .ant-typography-expand {
    color: #1890ff;
    transition: color 0.3s ease;
  }
  
  .ant-typography-paragraph .ant-typography-expand:hover {
    color: #40a9ff;
  }
  